<template>
    <section>
        <div class="section-body">
            <template v-if="specialists">
                <template v-if="!fdLoading">
                    <FilteredDoctors v-if="filteredDoctors"/>
                    <template v-else>
                        <template v-if="!searchbarSpecialist.input.value">
                            <div class="src-inner" v-if="specialists.length">
                                <div class="src-card" v-for="specialist in specialists" :key="specialist.id">
                                    <div class="src-card-inner" @click="selectSpecialist(specialist); pageScrollTop();">
                                        <div class="sci-icon">
                                            <img :src="imgsrc + specialist.image" alt="melinda hospital" v-if="specialist.image"/>
                                            <img src="../../assets/favicon.webp" alt="melinda hospital" v-else/>
                                        </div>
                                        <div class="sci-descript">
                                            <h3>{{ specialist.name }}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="search-not-found">
                                <p>Tidak ditemukan dokter dengan kata kunci :</p>
                                <div v-if="searchbarSpecialist.input.value">"{{ searchbarSpecialist.input.value }}"</div>
                                <div v-if="searchbarSelectSpecialist.input.value.length > 0">"{{ searchbarSelectSpecialist.input.value }}"</div>
                                <div v-if="searchbarSpecialist.select.value != 'all'">dalam {{ searchbarSpecialist.select.title }}</div>
                                <div v-else>dalam Semua Spesialis</div>
                            </div>
                        </template>
                    </template>
                </template>
                <FilteredDoctorsSkeleton v-else/>
            </template>
            <SpecialistListSkeleton v-else/>
        </div>
    </section>
</template>
<script setup>
    import { computed } from "@vue/runtime-core";
    import { useDoctorStore } from "../../stores/doctor";
    import SpecialistListSkeleton from "../skeletons/SpecialistListSkeleton.vue";
    import FilteredDoctors from "../doctor/FilteredDoctorsComponent.vue";
    import { selectSpecialist } from "@/functions/doctor";
    import { pageScrollTop } from "@/functions/general";
    import FilteredDoctorsSkeleton from "../skeletons/FilteredDoctorsSkeleton.vue";
    const specialists = computed(() => useDoctorStore().specialistShow);
    const filteredDoctors = computed(() => useDoctorStore().filteredDoctors);
    const searchbarSpecialist = computed(() => useDoctorStore().searchbarSpecialist);
    const searchbarSelectSpecialist = computed(() => useDoctorStore().searchbarSelectSpecialist);
    const fdLoading = computed(() => useDoctorStore().fdLoading);
    useDoctorStore().getAllDoctors();
    useDoctorStore().setSpecialists();
</script>
<style scoped>
    section{
        padding: 0 9% 2.5rem 9%;
        width: 100%;
        transition: 0.2s;
        color: var(--grey);
    }
    .section-body{
        width: 100%;
    }
    .src-inner{
        display: flex;
        flex-wrap: wrap;
        transition: 0.2s;
    }
    .src-card{
        width: 25%;
        padding: 1%;
        transition: 0.2s;
        user-select: none;
    }
    .src-card a{
        text-decoration: none;
        color: var(--grey);
    }
    .src-card-inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 120px;
        height: 100%;
        padding: 1%;
        background: var(--white);
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        overflow: hidden;
        justify-content: center;
        cursor: pointer;
        /* background-image: url('../../assets/images/background/sbluemgmd.jpg');
        background-repeat: no-repeat;
        background-position: top left;
        background-size: cover; */
    }
    .sci-icon{
        width: 100%;
        overflow: hidden;
        align-items: center;
        text-align: center;
    }
    .sci-icon img{
        max-width: 100%;
        max-height: 100%;
    }
    .sci-descript{
        color: var(--grey);
        width: 100%;
        text-align: center;
    }
    .sci-descript h3{
        font-size: 0.9rem;
        margin: 0;
    }
    .search-not-found{
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        text-align: center;
        color: var(--grey);
        word-break: break-all;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 4%;
            padding-right: 4%;
        }
        .src-card-inner{
            border-radius: 5px;
        }
    }
    @media only screen and (max-width: 1100px){
        .src-card{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 1%;
            padding-right: 1%;
        }
        .src-card{
            width: 50%;
            /* margin-bottom: 1rem; */
        }
        .src-card-inner{
            min-height: 200px;
            box-shadow: unset;
            border: 1px solid var(--softgrey);
        }
    }
    @media only screen and (max-width: 290px){
        .src-card{
            width: 100%;
        }
    }
</style>