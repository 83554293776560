<template>
    <div class="content">
        <!-- <TopBackgroundComponent/> -->
        <DarkCrumbComponent :data="crumbdata"/>
        <CekTarifDataDetailComponent/>
    </div>
</template>

<script setup>
    // import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import DarkCrumbComponent from '@/components/DarkCrumbComponent.vue';
    import CekTarifDataDetailComponent from '@/components/tools/CekTarifDataDetailComponent.vue';
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Data Tarif',
            type: 'link',
            to: '/tool/cek-tarif/data'
        },
        {
            title: 'Detail Data Tarif',
            type: 'text'
        }
    ];
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>