<template>
    <div 
      class="slider-container" 
      @mousedown="startDrag" 
      @mousemove="onDrag" 
      @mouseup="endDrag"
      @mouseleave="endDrag"
      @touchstart="startDrag" 
      @touchmove="onDrag" 
      @touchend="endDrag"
    >
      <div 
        class="slider" 
        :style="{ transform: `translateX(calc(-${currentIndex * 100}% + ${dragOffset}px))`, transition: isDragging ? 'none' : 'transform 0.5s ease' }"
      >
        <div v-for="(image, index) in images" :key="index" class="slide">
          <img :src="image" alt="Slide image" draggable="false" />
        </div>
      </div>
      
      <div class="dots">
        <span 
          v-for="(image, index) in images" 
          :key="index" 
          class="dot" 
          :class="{ active: index === currentIndex }" 
          @click="goToSlide(index)"
          @touchend="goToSlide(index)">
        </span>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  const images = ref([
    new URL('@/assets/images/pictures/home-slider/rsiam.jpg', import.meta.url).href,
    new URL('@/assets/images/pictures/home-slider/rsm2.png', import.meta.url).href,
    new URL('@/assets/images/pictures/home-slider/mcvc.jpg', import.meta.url).href
  ]);
  
  const currentIndex = ref(0);
  const isDragging = ref(false);
  const startX = ref(0);
  const dragOffset = ref(0);
  const threshold = 50; // Minimal geser sebelum slide pindah
  
  const goToSlide = (index) => {
    currentIndex.value = index;
  };
  
  const startDrag = (event) => {
    // event.preventDefault(); // Mencegah efek bawaan (misal drag image)
    isDragging.value = true;
    startX.value = event.clientX || event.touches?.[0].clientX;
    dragOffset.value = 0;
  };
  
  const onDrag = (event) => {
    if (!isDragging.value) return;
    // event.preventDefault(); // Mencegah scroll di perangkat mobile saat geser
    const currentX = event.clientX || event.touches?.[0].clientX;
    dragOffset.value = currentX - startX.value;
  };
  
  const endDrag = () => {
    if (!isDragging.value) return;
    isDragging.value = false;
  
    if (dragOffset.value < -threshold && currentIndex.value < images.value.length - 1) {
      currentIndex.value++;
    } else if (dragOffset.value > threshold && currentIndex.value > 0) {
      currentIndex.value--;
    }
  
    dragOffset.value = 0; // Reset agar tidak terseret terus
  };
  </script>
  
  <style scoped>
  .slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    max-width: 800px;
    margin: auto;
    user-select: none;
    touch-action: pan-y;
    cursor: grab;
  }
  
  .slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .slide {
    min-width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  
  img {
    width: 100%;
    display: block;
    pointer-events: none; /* Pastikan tidak bisa diseleksi */
  }
  
  .dots {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;
    margin-top: 10px;
    z-index: 1;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background-color: var(--white);
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dot.active {
    background-color: var(--primary);
  }
  </style>
  