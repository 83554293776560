<template>
    <div class="content">
        <DarkCrumbComponent :data="crumbdata"/>
        <CekTarifDataComponent/>
    </div>
</template>

<script setup>
    import DarkCrumbComponent from '@/components/DarkCrumbComponent.vue';
    import CekTarifDataComponent from '@/components/tools/CekTarifDataComponent.vue';
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Data Tarif',
            type: 'text',
        }
    ];
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>