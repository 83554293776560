import { defineStore } from "pinia";
// import axiosClient from "../axios.js";
// import axiosOne from "../axios_one.js";
import axiosClient from "../axios.js";
import { clearInputs, clearCheckBox } from "@/functions/general.js";
import { showEventnotif } from "@/functions/event.js";
// import { clearInput } from "@/functions/general.js";
// import { showEventnotif } from "@/functions/event.js";

export const useEventFormStore = defineStore("eventform", {
    state: () => ({
        // ==================== states used on event form page and its relation
        promoList: null,
        promoDetail: null,
        homePromo: null,
        // =====
        eventForm: null,
        webinar: null,
        eventLoader: null,
        userAgent: navigator.userAgent,
        contacts: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on event form page and its relation
        async setPromoList(){
            try{
                const response = await axiosClient.get(`ev-attendance`);
                this.promoList = response.data.data;
                // console.log(this.promoList);
            }catch(error){
                console.log(error.response);
            }
        },
        async setPromoDetail(slug){
            try{
                const response = await axiosClient.get(`ev-attendance/` + slug);
                this.promoDetail = response.data.data;
                console.log(this.promoDetail);
            }catch(error){
                console.log(error.response);
            }
        },
        setHomePromo(){
            // for(var i = 0; i < 15; i++){
            //     for(var j = 0; j < this.promoList.length; j++){
            //         this.promoList.push();
            //     }
            // }
        },
        // =====
        async setEventForm(slug){
            try{
                const response = await axiosClient.get(`events/form/` + slug);
                this.eventForm = response.data.data;
                // console.log(this.eventForm);
                // const date = new Date();
                // const dateClose = new Date(response.data.register_close);
                // if(date.getTime() < dateClose.getTime()){
                //     this.eventFormStatus = true;
                // }else{
                //     this.eventFormStatus = false;
                // }
                // console.log(this.eventFormStatus);
                // console.log('Now : ' + date.getTime() + ' - Close : ' + dateClose.getTime());
                console.log(response);
            }catch(error){
                console.log(error.response);
            }
        },
        async setWebinar(slug, fdata, params){
            this.setEventLoader(1);
            try{
                const response = await axiosClient.post(`events/form/` + slug, fdata);
                if(response.data.success){
                    var tt = "Saya telah mendaftar pada " + this.eventForm.name + " dengan data : Nama : " + params.name + ", No Telepon : " + params.phone + ", Email : " + params.email + ", RS Tujuan : " + params.hospital + ". Tolong bantu diproses";
                    const contacts = [
                        {
                            hospital: 'RSIA Melinda',
                            name: 'Admission RSIA Melinda',
                            phone: '+6285223388888',
                            text: tt
                        },
                        {
                            hospital: 'RS Melinda 2',
                            name: 'Admission RS Melinda 2',
                            phone: '+628157100888',
                            text: tt
                        },
                        {
                            hospital: 'Melinda Cardio Vascular',
                            name: 'Admission Melinda CVC',
                            phone: '+628157195777',
                            text: tt
                        }
                    ];
                    this.contacts = contacts;
                    this.webinar = response.data;
                    this.webinar.contacts = contacts;
                    for(var i = 0; i < this.contacts.length; i++){
                        if(this.contacts[i].hospital == params.hospital){
                            this.contacts[i].selected = true;
                        }
                    }
                    showEventnotif('', response.data.message, 60000);
                    clearInputs('input');
                    clearCheckBox('checkbox');
                    if(this.webinar.link){
                        window.open(this.webinar.link);
                    }
                    // console.log(response);
                    // console.log(this.eventForm);
                    // console.log(this.webinar);
                    // console.log(params);
                }else{
                    showEventnotif('', response.data.message, 60000);
                }
                console.log(response);
                this.setEventLoader(null);
            }catch(error){
                this.setEventLoader(null);
                console.log(error);
                if(error.response?.data?.errors?.phone){
                    error.response.data.errors.phone.forEach(element => {
                        showEventnotif('', 'Gagal<br/>'+ element +'<br/>', 5000);
                    });
                }
            }
        },
        setEventLoader(value){
            this.eventLoader = value;
        }
    }
});