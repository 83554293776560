<template>
    <CekTarifComponent/>
</template>

<script setup>
    import CekTarifComponent from '@/components/tools/CekTarifComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>