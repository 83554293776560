<template>
    <section>
        <div class="section-head">
            <h1>Detail Tarif</h1>
        </div>
        <div class="section-body">
            <div class="service-detail">
                <ul>
                <li><strong>SERVICECODE:</strong> {{ service.SERVICECODE }}</li>
                <li><strong>SERVICECODE DESCRIPTION:</strong> {{ service.SERVICECODE_DESCRIPTION }}</li>
                <li><strong>SERVICECODE KELAS:</strong> {{ service.SERVICECODE_KELAS }}</li>
                <li><strong>KELAS:</strong> {{ service.KELAS }}</li>
                <li><strong>RUANG BEDAH/NON RUANG BEDAH:</strong> {{ service.SURGERY_OR_NON_SURGERY }}</li>
                <li><strong>HELPER:</strong> {{ service.HELPER }}</li>
                <li><strong>TARIFF:</strong> {{ service.TARIFF }}</li>
                <li><strong>VALID DATE FROM:</strong> {{ formatDate(service.VALID_DATE_FROM) }}</li>
                <li><strong>END DATE TO:</strong> {{ formatDate(service.END_DATE_TO) }}</li>
                <li><strong>STATUS:</strong> {{ service.STATUS }}</li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { ref } from "vue";

    const service = ref({
        SERVICECODE: "001",
        SERVICECODE_DESCRIPTION: "Knee Surgery",
        SERVICECODE_KELAS: "A",
        KELAS: "VIP",
        SURGERY_OR_NON_SURGERY: "Surgery",
        HELPER: "Yes",
        TARIFF: "5000000",
        VALID_DATE_FROM: new Date(2024, 9, 1),
        END_DATE_TO: new Date(2024, 12, 31),
        STATUS: "Active",
    });

    const formatDate = (date) => {
        return new Intl.DateTimeFormat("id-ID").format(date);
    };
</script>

<style scoped>
    section{
        /* padding: 0 10% 2.5rem 10%; */
        transition: 0.2s;
    }
    .section-head{
        padding: 0 10% 0 10%;
        transition: 0.2s;
    }
    .section-head h1{
        margin: 0 0 1rem 0;
        font-size: 2rem;
        transition: 0.2s;
    }
    .section-body{
        padding: 0 10% 2.5% 10%;
    }
    .service-detail {
        /* padding: 20px; */
        font-family: Arial, sans-serif;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        margin-bottom: 10px;
    }

    strong {
        width: 200px;
        display: inline-block;
    }
    @media only screen and (max-width: 1500px){
        .section-head{
            padding: 0 5% 0 5%;
        }
        .section-body{
            padding: 0 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        .section-head{
            padding: 0 2.5% 0 2.5%;
        }
        .section-body{
            padding: 0 2.5% 2.5rem 2.5%;
        }
    }
</style>